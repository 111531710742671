<template>
    <div style="padding-left:40px">
        <div v-if='document' style="font-weight:bold;">关于 {{document.name}} 的文档存证周转</div>
        <div id="fileChainBox" style="height: 400px"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts'
import { chainAPI } from "liankong-ui-api"

export default {
  components: {},
  data () {
     const date = this.$options.filters['date']
    return {
      title: null,
      visible: false,
      document:null,
      targetNameNode:'',
      chartOptions: {
        tooltip: {
          show: true,
          trigger: "item",
          triggerOn: "mousemove",
          formatter: function (params) {
            
            const data = params.data
            if(data.type == 'doc'){
              return `文档信息:<br>文档号：${data.id}<br>文档名：${data.name}<br>创建日期：${date(data.createDate)}`
            } else if(data.type == 'share'){
              let sharesInfo = '共享信息:<br>'
              data.shares.forEach(item => {
                 sharesInfo+=`共享号：${item.id}<br> 共享名：${item.name}<br> 创建日期：${date(item.createDate)}<br>`
              });
              return sharesInfo
              // const targetCount = data._data.targets.length
              // return `共享信息:<br>共享号：${data.id}<br>共享名：${data.name}<br>共享人：${data.userName}<br>共享目标：${data.targetName}<br>创建日期：${date(data.createDate)}`
            }else if(data.type == 'sharedoc'){
              // const targetCount = data._data.targets.length
              let shareDocInfo = ''
              shareDocInfo = `共享信息:<br>共享号：${data.id}<br>共享名：${data.name}<br>共享人：${data.userName}<br>共享目标：${data.targetName}<br>`
              shareDocInfo +=`共享权限:${data.permissions}<br>共享资源:${data.resources}<br>创建日期：${date(data.createDate)}`
              return `<div style='width:60%;word-break: break-all;word-wrap: break-word;white-space: pre-wrap;'>${shareDocInfo}</div>`
            }else if(data.type == 'target'){
              // const userName = data._data.attrs && data._data.attrs.name || data._data.value
              return `授权信息:<br>授权号：${data.id}<br>授权类型：${data.typeLabel}<br>被授权人：${data.name}`
            } else {
              return ''
            }
          },
          position: function (pos, params, dom, rect, size) {
            var obj = { top: pos[1] }

            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧
            if (pos[0] > size.viewSize[0] / 2) {
              obj.right = size.viewSize[0] - pos[0] + 8
            } else {
              obj.left = pos[0] + 8
            }

            return obj
          },
        },
        series: [
          {
            type: "tree",
            // layout: 'radial',

            data: [],

            top: "5%",
            // left: "25%",
            bottom: "5%",
            right: "5%",
            // width:'100%',
            // symbol(value, params){
            //   return null
            // },
            symbolSize: 12,

            // edgeShape: 'polyline', // 直线
            // orient: 'vertical', //竖着
            roam: true,
            expandAndCollapse: false,

            label: {
              position: "bottom",
              // verticalAlign: 'bottom',
              align: "left",
              fontSize: 12,
              formatter(params) {
                const data = params.data

                switch(data.type){
                  case 'doc':
                  case 'target':
                    return data.name
                  case 'share'://崔总要求去掉省略号
                    // return data.shareName&&data.shareName.length>15?data.shareName.slice(0,15) + '...' : data.shareName
                    return data.shareName
                  case 'sharedoc':
                    // return data.userName.length>15?data.userName.slice(0,15) + '...' : data.userName
                    return data.userName
                  default:
                    return null
                }
              },
            },
            leaves: {
              label: {
                position: "right",
                verticalAlign: "middle",
                align: "left",
                fontSize: 12,
              },
            },

            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      },
    }
  },
  computed: {},
  watch: {},
  methods: {
    //更新图表
    updateChart(){
      this.charts.hideLoading() 
      this.charts.setOption(this.chartOptions)
      window.onresize =  this.charts.resize;
    },

    //加载共享授权链数据
    async loadShareChainData(id) {
      let { code, data, message } = await chainAPI.chainByShare(id)
      if (code == 0) {
        this.initChart()

       
        let targetName = ''
        data.targets.map(ii=>{
            targetName += ii.attrs.name+'、'
          })
          //当前节点
        const changePermissions =  {
          "print":{0:'不可打印',1:'可打印'},
          // "download":{0:'不可下载',1:'可下载'},
          // "edit":{0:'不可编辑',1:'可编辑'},
          // "copy":{0:'不可复制',1:'可复制'},
          "share":{0:'不可共享',1:'可共享'},
          "isDynamic":{0:'无动态水印',1:'有动态水印'}
        }
        let permissions= ''
        let resources = ''
        let copyPermissions = ''
        let shadeAreaIdPermissions = ''
        data.permissions.forEach((map, p) => {
            if(changePermissions[`${map.name}`]){
              permissions+= changePermissions[`${map.name}`][`${map.value}`] + ','
            } else if(map.name=='shadeAreaId'&&map.value!='0'){
              shadeAreaIdPermissions= '有遮掩,'
            } else if (map.name=='shadeAreaId'&&map.value=='0') {
              shadeAreaIdPermissions= '无遮掩,'
            } else if (map.name=='copy') {
              let copyObj = {
                "copy":{0:'不可复制',1:'可复制'},
              }
              copyPermissions= copyObj[`${map.name}`][`${map.value}`]
            }
        })

        if(shadeAreaIdPermissions=='有遮掩,'){
          permissions+= '不可复制,有遮掩,'
        } else {
          permissions+= copyPermissions + ',' +'无遮掩,'
        }

        permissions = permissions.slice(0,permissions.length-1)
        if(data.resources.length>3){
          data.resources.slice(0,4).forEach(item=>{
              resources += `${item.name},`
          })
         resources = resources.slice(0,resources.length-1)
          resources += '等'
        } else {
          data.resources.forEach(item=>{
              resources += `${item.name},`
          })
        resources = resources.slice(0,resources.length-1)
        }
        const node = {
            id: data.shareId,
            name: data.shareName || "共享节点",
            description: data.description,
            createDate: data.createDate,
            type: 'sharedoc',
            userName:data.userName + "创建的共享",
            targetName:targetName,
            permissions,
            resources,
            _data: data
          }
          this.chartOptions.series[0].data[0].type = 'sharest'
        this.chartOptions.series[0].data[0].children=[node]
        const rootNode = this.chartOptions.series[0].data[0].children[0]

        this.handleDocumentChain(rootNode, data)
        
        //更新图表
        this.updateChart()
      }else{
        this.$message.error(message)
      }
    },

     //加载文档授权链数据
     async loadDocumentChainData(id){
      const { code, data, message } = await chainAPI.chainByDocs(id)
      if (code == 0) {
        this.initChart()
        
        const rootNode = this.chartOptions.series[0].data[0]
        this.handleDocumentChainData(rootNode, data)

        //更新图表
        this.updateChart()
      }else{
        this.$message.error(message)
      }
    },

    //处理文档链节、共享链节点点数据
    handleDocumentChainData(treeNode, data){
      const children = []

      //子节点
      if(data && data.length > 0){
        
        data.map(i => {
          //当前节点
          let node = {}

          if(i.targetName != '' || i.targetName != null){
            if(i.targetName != null){
               if(i.children){
                node = {
                  shareName:i.sourceName + '共享给' + i.targetName + ' ' + i.count + '次',
                  shares:i.shares,
                  type: 'share',
                  collapsed:i.children.length>0? false : true
                }
              }else{
                node = {
                  shareName:i.sourceName + '共享给' + i.targetName + ' ' + i.count + '次',
                  shares:i.shares,
                  type: 'share',
                  collapsed:false
                }
              }
            }else{
                node = {
                  shareName:i.sourceName +'的共享',
                  shares:i.shares,
                  type: 'share',
                  collapsed:false
                }
            }
           
          
            if( i.children && i.children.length>0 ){
              this.handleDocumentChainData(node, i.children)
            }
            children.push(node)
          }
        })
      }

      //目标节点
      if(data.targets && data.targets.length > 0){
        data.targets.map(i => {

          const userName = i.attrs && i.attrs.name || i.value

          //当前节点
          const node = {
            id: i.id,
            name: userName,
            typeLabel: i.type == "TARGET_ID" ? "平台用户授权" : "手机号授权",
            description: i.value,
            createDate: null,
            type: 'target',
            _data: i
          }
          // children.push(node)
        })
      }

      treeNode.children = children
    },
    handleDocumentChain(treeNode, data){
      const children = []

      //子节点
      if(data.children && data.children.length > 0){
        
        data.children.map(i => {
          let targetName = ''
          i.targets.map(ii=>{
            targetName += ii.attrs.name+'、'
          })
          //当前节点
        const changePermissions =  {
          "print":{0:'不可打印',1:'可打印'},
          // "download":{0:'不可下载',1:'可下载'},
          // "edit":{0:'不可编辑',1:'可编辑'},
          // "copy":{0:'不可复制',1:'可复制'},
          "share":{0:'不可共享',1:'可共享'},
          "isDynamic":{0:'无动态水印',1:'有动态水印'}
        }
        let permissions= ''
        let resources = ''
        let copyPermissions = ''
        let shadeAreaIdPermissions = ''
        i.permissions.forEach((map, p) => {
          if(changePermissions[`${map.name}`]){
              permissions+= changePermissions[`${map.name}`][`${map.value}`] + ','
            } else if(map.name=='shadeAreaId'&&map.value!='0'){
              shadeAreaIdPermissions= '有遮掩,'
            } else if (map.name=='shadeAreaId'&&map.value=='0') {
              shadeAreaIdPermissions= '无遮掩,'
            } else if (map.name=='copy') {
              let copyObj = {
                "copy":{0:'不可复制',1:'可复制'},
              }
              copyPermissions= copyObj[`${map.name}`][`${map.value}`]
            }
        })
        if(shadeAreaIdPermissions=='有遮掩,'){
          permissions+= '不可复制,有遮掩,'
        } else {
          permissions+= copyPermissions + ',' +'无遮掩,'
        }
        permissions = permissions.slice(0,permissions.length-1)
        if(i.resources.length>3){
          i.resources.slice(0,4).forEach(item=>{
              resources += `${item.name},`
          })
         resources = resources.slice(0,resources.length-1)
          resources += '等'
        } else {
          i.resources.forEach(item=>{
              resources += `${item.name},`
          })
        resources = resources.slice(0,resources.length-1)
        }
        const node = {
            id: i.shareId,
            name: i.shareName || "共享节点",
            description: i.description,
            createDate: i.createDate,
            type: 'sharedoc',
            userName:i.userName + "创建的共享",
            targetName:targetName,
            permissions,
            resources,
            _data: i
          }

          this.handleDocumentChain(node, i)

          children.push(node)
        })
      }

      //目标节点
      if(data.targets && data.targets.length > 0){
        data.targets.map(i => {

          const userName = i.attrs && i.attrs.name || i.value

          //当前节点
          const node = {
            id: i.id,
            name: userName,
            typeLabel: i.type == "TARGET_ID" ? "平台用户授权" : "手机号授权",
            description: i.value,
            createDate: null,
            type: 'target',
            _data: i
          }
          // children.push(node)
        })
      }

      treeNode.children = children
    },
    show(type, document){
        this.document = document
      //构建根节点
      this.chartOptions.series[0].data[0] = {
        id: document.id,
        name: document.name,
        description: document.name,
        createDate: document.createDate,
        type: type,
        userName:document.ownerName,
        targetName:this.targetNameNode,
        _data: document
      }

      this.visible = true

      if(type == 'doc'){
        this.title = '文档链'

        //加载文档链数据
        this.loadDocumentChainData(document.id)
      }else{
        this.title = '查看共享授权链'

        //加载共享链数据
        this.loadShareChainData(document.id)
      }
     
      
    },

    initChart(){
      if(!this.charts){
        let fileChainBox = document.getElementById("fileChainBox");
        fileChainBox.removeAttribute("_echarts_instance_");//加上这句
        this.charts = echarts.init(fileChainBox);
      }

      this.charts.clear();
      this.charts.showLoading()
     
    }
  },
  created () {

  },
  mounted () {
    /* this.$nextTick(() => {
      this.initChart()
    }) */
  },
}
</script>