<template>
    <div class="evidence-container">
        <el-row>
            <el-col :span="4">
                <div class="evidence-statistics">
                    <i class="el-icon-share"></i>
                    <div>
                        <p>节点数</p>
                        <p>28 个</p>
                    </div>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="evidence-statistics">
                    <i class="el-icon-s-help"></i>
                    <div>
                        <p>事务数</p>
                        <p>{{sysTotal+shareTotal+userTotal+readTotal}} 条</p>
                    </div>
                </div>
            </el-col>
             <el-col :span="4">
                <div class="evidence-statistics">
                     <i class="el-icon-s-custom"></i>
                    <div>
                        <p>账户存证</p>
                        <p>{{userTotal}} 条</p>
                    </div>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="evidence-statistics">
                     <i class="el-icon-document"></i>
                    <div>
                        <p>文件存证</p>
                        <p>{{sysTotal}} 条</p>
                    </div>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="evidence-statistics">
                     <i class="el-icon-setting"></i>
                    <div>
                        <p>授权存证</p>
                        <p>{{shareTotal}} 条</p>
                    </div>
                </div>
            </el-col>
             <el-col :span="4">
                <div class="evidence-statistics">
                    <i class="el-icon-s-management"></i>
                    <div>
                        <p>阅读存证</p>
                        <p>{{readTotal}} 条</p>
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="6">
                <el-form :inline="true" size='small' :model="query" class="demo-form-inline">
                    <el-form-item class="formItem">
                        <el-input v-model="query.name" placeholder="请输入文件名"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">查询</el-button>
                    </el-form-item>
                    </el-form>
                <div class="">
                    <el-table size='small'
                        border
                        :data="tableData"
                        @row-click='handleClick'
                        style="width: 100%">
                        <el-table-column
                            align="center"
                            width="90"
                            label="区块高度">
                             <template slot-scope="scope">
                                {{scope.row.id.substr(0,5)}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                             width="100"
                            align="center"
                            label="事务类型">
                            <template>文档上链</template>
                        </el-table-column>
                        <el-table-column
                            prop="signInfo"
                            label="数据哈希值">
                            <template slot-scope="scope">
                                0X{{scope.row.signInfo}}
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                    :page-size="query.pageSize"
                    :pager-count="5"
                    @current-change="pageChanged"
                    layout="prev, pager, next"
                    :total="documentTotal">
                    </el-pagination>
                </div>
            </el-col>
            <el-col :span="18">
                <el-row>
                    <el-col :span='17'>
                        <div style="padding-left:40px">
                            <div id="readMyEcharts" style="height: 400px"></div>
                        </div>
                    </el-col>
                    <el-col :span="7">
                        <div  id="pie_chart" style="height: 350px"></div>
                    </el-col>
                </el-row>
                <div>
                    
                    <flowChart ref='flowChart'></flowChart>
                </div>
            </el-col>
        </el-row>

    </div>
</template>
<script>
import * as echarts from 'echarts'
import EchartsMixins from '../monitor/echartsMixins'
import flowChart from './flowChart.vue'
import { monitormanagerAPI ,usermanagerAPI } from 'liankong-ui-api'
export default {
    mixins: [EchartsMixins],
    components:{
        flowChart
    },
    data(){
        return {
            sysTotal: 0,
            shareTotal: 0,
            readTotal:0,
            userTotal:0,
            documentTotal:0,
            tableData: [],
            readMaxCounts: '0',
            readOptions: [{
                value: '0',
                label: '全部'
            },{
                value: '1',
                label: '日'
            }, {
                value: '2',
                label: '月'
            }, {
                value: '3',
                label: '周'
            }],
            query:{
                pageNum:1,
                pageSize:5,
                name:null
            },
            readEchartsyXisData: [50,140,170,230,200,110,370],
        }
    },
    methods:{
        // 柱状图
        getSelectReadList(list){
            let xAxis = []
            let data = []
            list.map(item =>{
                xAxis.push(item.name)
                data.push(item.readCount)
            })
             let shareMyEcharts = echarts.init(document.getElementById('readMyEcharts'));

            let option = {
                title:{
                    text:'阅读次数量排行榜'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    data: xAxis,
                    axisLabel: {
                        interval: 0,
                        rotate: 20
                    },
                    axisTick: {
                        alignWithLabel: true
                    }
                }],
                yAxis: [{
                    type: 'value'
                }],
                series: [{
                    name: '',
                    type: 'bar',
                    barWidth: '60%',
                    data: data
                }]
            };

            option && shareMyEcharts.setOption(option);
        },
        getUserList(){
            usermanagerAPI.getUserList({}).then(res=>{
                if(res.code == '0') {
                    this.userTotal = res.data.total;
                    setTimeout(()=>{
                        this.initChart()
                    },1000)
                }
            })
        },
     
        // 检索
        onSubmit(){
            this.query.pageNum = 1;
            this.getDocumentList()
        },
        
        // 系统文件总数
        getSysLists(){
            monitormanagerAPI.getSysTotalList().then(res=>{
                if(res.code == '0') {
                    this.sysTotal = res.data.total;
                }
            })
        },
        // 共享和请求
        getShareList(){
            monitormanagerAPI.getShareInfosList().then(res=>{
                if(res.code == '0') {
                    this.shareTotal = res.data.shareCount;
                }
            })
        },
        getDocumentReadCount(){
            monitormanagerAPI.getDocumentReadCount().then(res=>{
                if(res.code == '0') {
                    this.readTotal = res.data.total;
                }
            })
        },
        // 饼图
        initChart(){
            let fileChainBox = document.getElementById("pie_chart")
            let fileChainBoxEchart  = echarts.init(fileChainBox);

            let option = {
                tooltip: {
                    trigger: 'item'
                },
                series: [
                {
                    name: '访问来源',
                    type: 'pie',
                    radius: '50%',
                    data: [
                        {value: this.userTotal, name: '账户存证', key: "0"},
                        {value: this.sysTotal, name: '文件存证', key: "1"},
                        {value: this.shareTotal, name: '授权存证', key: "2"},
                        {value: this.readTotal, name: '阅读存证', key: "3"},
                    ],
                    emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                    }
                }
                ]
            }

             fileChainBoxEchart.setOption(option)
        },
        getReadRank(){
            monitormanagerAPI.getReadRank().then(res=>{
                if(res.code == '0') {
                    this.getSelectReadList(res.data);
                }
            })
        },
        getDocumentList(){
            monitormanagerAPI.getDocumentList(this.query).then(res=>{
                if(res.code == '0') {
                    this.tableData = res.data;
                    this.documentTotal = res.total
                    if(res.data.length != 0){
                         this.$refs['flowChart'].show('doc',res.data[0])
                    }
                }
            })
        },
        pageChanged(num){
            this.query.pageNum = num;
            this.getDocumentList()
        },
        handleClick(row){
            this.$refs['flowChart'].show('doc',row)
        },
    },
    mounted(){
        
        this.getSysLists()
        this.getShareList()
        this.getDocumentReadCount()
        this.getUserList()
        this.getReadRank()
        this.getDocumentList()
        
    }
}
</script>
<style lang="less" scoped>
.evidence-container{
    color: #333;
    padding: 25px;
    .evidence-statistics{
        padding: 0px 0 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        i{
            font-size: 50px;
            margin-right: 10px;
            color: #508ef3;
        }
        p{
            font-size: 16px;
            margin: 5px 0;
        }
        p:nth-child(2){
            font-weight: bold;
            font-size: 18px;
        }
    }
    .formItem{
        width: 70%;
        /deep/.el-form-item__content{
            width: 100%;
        }
    }
}
</style>